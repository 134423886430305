const Queries = {
  supplier: "SUPPLIER",
  productsMovement: "PRODUCTS_MOVEMENT",
  products: "PRODUCTS",
  posProducts: "POS_PRODUCTS",
  stockJournal: "STOCK_JOURNAL",
  shopSettingInfo: "SHOP_SETTING_INFO",
  productsStat: "PRODUCTSSTAT",
  exprenseCategory: "exprenseCategory",
  duePurchaseList: "duePurchaseList",
  purchaseList: "purchaseList",
  paymentList: "paymentList",
  paymentMethods: "paymentMethods",
  bankAccounts: "bankAccounts",
  chartOfAccounts: "chartOfAccounts",
  chartOfAccountsLedger: "chartOfAccountsLedger",
  journalReport: "journalReport",
  trailBalance: "trailBalance",
  accountBalances: "AccountBalances",
  dashboardData: "DashboardData",
  branchDashboardData: "DashboardData",
  cashflowdData: "CashflowdData",
  getMonthlyTransactionCount: "getMonthlyTransactionCount",
  chartData: "chartData",
  stuffUsers: "StuffUsers",
  userRoles: "UserRoles",
  permissions: "Permissions",
  posSales: "POS_SALES",
  customers: "CUSTOMERS",
  salesList: "SALES_LIST",
  quotationList: "QUOTE_LIST",
  catalog: "CATALOG",
  subscription: "subscription",
  currentPackage: "currentPackage",
  currentSubscription: "CurrentSubscription",
  cmsFeatures: "CMSFeatures",
  singleFeatures: "singleFeatures",
  cmsHomePage: "CMS_HOMEPAGE",
  productOrder: "ProductOrder",
  productStocks: "ProductStocks",
  productMovement: "ProductMovement",
  CMS_BLOG: "CMS_BLOG",
  CMS_FEATURED_BLOG: "CMS_FEATURED_BLOG",
  CMS_RECENT_BLOG: "CMS_RECENT_BLOG",
  WARE_HOUSE: "WAREHOUSE",
  WARE_HOUSE_FORM: "WAREHOUSE_FORM",
  WAREHOUSE_PRODUCT_STOCK: "PRODUCT_STOCK_IN_WAREHOUSE",
  BRANCHES: "BRANCHES",
  BRANCH_FORMS: "BRANCHE_FORMS",

  SERVICE_FORMS: "SERVICE_FORMS",
  SERVICES: "SERVICE_FORMS",
  SERVICE_INVOICE: "SERVICE_INVOICE",
  SHOPIFY: "SHOPIFY",
  SHIPPER: "SHIPPER",
};

export default Queries;
